import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './BillNew.css';

function BillNew() {
  const [acceptedFiles, setAcceptedFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setAcceptedFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
  }, []);
  
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, 
	multiple: true
   });
  
  function uploadFiles() {
    console.log(acceptedFiles);
  }

  function resetFiles(){
    setAcceptedFiles([]);
    console.log("Files have been reset")
  }

  return (
    <div>
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed #cccccc',
          padding: '20px',
          textAlign: 'center',
          borderRadius: '5px',
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
      </div>
      <div>
        <button onClick={uploadFiles} className='uploadFiles'>Upload :D</button>
        <button onClick={resetFiles} className='resetFiles'>Reset D:</button>
      </div>
    </div>
  );
}

export default BillNew;
