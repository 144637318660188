export const storeEmailPassword = (email, password) => {
	localStorage.setItem('email', email);
	localStorage.setItem('password', password);
	return true;
};

export const readEmailPassword = () => {
	var email = localStorage.getItem('email');
	var password = localStorage.getItem('password');
	return [email, password];
};

