import React from 'react';
import './BillList.css';
import { useNavigate } from "react-router-dom";

class BillList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            index: 0,
            itemsPerPage: 10, // Number of items to show per page
            currentPage: 1,
            count: 99999999,
            numberOfRecords: 0,
            buttonFormat: 0,
            searchText: this.props.searchBarContent,
            minPriceTotal: this.props.minPriceTotal,
            allData: [], // Store all fetched data
            recievedData: []
        };

        this.fetchAllData = this.fetchAllData.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.changeFormat = this.changeFormat.bind(this);
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
        this.handleMinPriceChange = this.handleMinPriceChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleItemsPerPageChange = this.handleItemsPerPageChange.bind(this);
    }

    componentDidMount() {
        this.fetchAllData(); 
        this.fetchData();
    }

    fetchData() {
        var {minPriceTotal, searchText, currentPage, itemsPerPage} = this.state;
        var index = (currentPage - 1) * itemsPerPage;
        var companyID = this.props.companyID;
        var year = 2024;

        if(minPriceTotal == null){
            minPriceTotal = 0
        }

        var url = "https://dashboardapi.plurato.com/searchbilllist?companyid=" + companyID;
        url = url + "&year=" + year;
        url = url + "&searchtext=" + searchText;
        url = url + "&minpricetotal=" + minPriceTotal;
        url = url + "&sortby=billID&sortasc=0";
        url = url + "&index=" + index;
        url = url + "&count=" + itemsPerPage;
        
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ recievedData: data, loading: false });
            })
            .catch((error) => {
                this.setState({ error: error.message, loading: false });
            });
    }

    fetchAllData() {
        var index = 0;
        var count = 99999999999;
        var companyID = this.props.companyID;
        var year = 2024;

        var url = "https://dashboardapi.plurato.com/getbilllist?companyid=" + companyID;
        url = url + "&year=" + year;
        url = url + "&sortby=billID&sortasc=1";
        url = url + "&index=" + index;
        url = url + "&count=" + count;

        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ allData: data, numberOfRecords: data.length });
            })
            .catch((error) => {
                this.setState({ error: error.message, loading: false });
            });
    }

    handlePageChange(newPage) {
        this.setState({ currentPage: newPage }, this.fetchData);
    }

    handleItemsPerPageChange(e) {
        const newItemsPerPage = parseInt(e.target.value);
        this.setState({ 
            itemsPerPage: newItemsPerPage,
            currentPage: 1 // Reset to first page when changing items per page
        }, this.fetchData);
        
    }

    handleSearchTextChange(e) {
        this.props.getSearchBarContent(e.target.value)
        this.setState({ 
            searchText: e.target.value,
            currentPage: 1 // Reset to first page when searching
        }, this.fetchData);
        
    }

    handleMinPriceChange(e) {
        this.props.getMinPriceTotal(e.target.value)
        var minPrice = parseFloat(e.target.value);
        if(isNaN(minPrice)){
            minPrice = null
        }
        this.setState({ 
            minPriceTotal: minPrice,
            currentPage: 1 // Reset to first page when filtering
        }, this.fetchData);
    }

    changeFormat() {
        this.setState((prevState) => ({
            buttonFormat: prevState.buttonFormat === 0 ? 1 : 0
        }), this.fetchData);
    }
    
    onBillClick(itemObj) {
        var billAutoID = itemObj.billAutoID;
        this.props.setSelectedBillAutoID(billAutoID);
        const navigate = this.props.navigate;
        navigate("/bills/view");
    }

    renderPagination() {
        const { currentPage, numberOfRecords, itemsPerPage } = this.state;
        const totalPages = Math.ceil(numberOfRecords / itemsPerPage);
        
        const pageNumbers = [];
        const maxVisiblePages = 5;
        let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
        
        if (endPage - startPage + 1 < maxVisiblePages) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }

        return (
            <div className="pagination-controls">
                <div className="items-per-page">
                    <select 
                        value={itemsPerPage} 
                        onChange={this.handleItemsPerPageChange}
                        className="items-per-page-select"
                    >
                        <option value="10">10 per page</option>
                        <option value="25">25 per page</option>
                        <option value="50">50 per page</option>
                        <option value="100">100 per page</option>
                    </select>
                </div>
                <div className="pagination-buttons">
                    <button 
                        onClick={() => this.handlePageChange(1)}
                        disabled={currentPage === 1}
                        className="pagination-button"
                    >
                        First
                    </button>
                    <button 
                        onClick={() => this.handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="pagination-button"
                    >
                        Previous
                    </button>
                    
                    {startPage > 1 && <span className="pagination-ellipsis">...</span>}
                    
                    {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(number => (
                        <button
                            key={number}
                            onClick={() => this.handlePageChange(number)}
                            className={`pagination-button ${currentPage === number ? 'active' : ''}`}
                        >
                            {number}
                        </button>
                    ))}
                    
                    {endPage < totalPages && <span className="pagination-ellipsis">...</span>}
                    
                    <button 
                        onClick={() => this.handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="pagination-button"
                    >
                        Next
                    </button>
                    <button 
                        onClick={() => this.handlePageChange(totalPages)}
                        disabled={currentPage === totalPages}
                        className="pagination-button"
                    >
                        Last
                    </button>
                </div>
                <div className="pagination-info">
                    Page {currentPage} of {totalPages} ({numberOfRecords} total records)
                </div>
            </div>
        );
    }

    render() {
        var { recievedData, loading, error, buttonFormat, searchText, minPriceTotal } = this.state;

        if (loading) {
            return <p>Loading...</p>;
        }

        if (error) {
            return <p>Error: {error}</p>;
        }

        return (
            <div className="main-container">
                <div className="controls-container">
                    <button className="changeFormat" onClick={this.changeFormat}>
                        Change Format
                    </button>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchText}
                        onChange={this.handleSearchTextChange}
                        className="search-input"
                    />
                    <input
                        type="number"
                        placeholder="Min Total Price..."
                        value={minPriceTotal}
                        onChange={this.handleMinPriceChange}
                        className="price-input"
                    />
                </div>
                
                {this.renderPagination()}
                
                <div className={buttonFormat === 0 ? 'button-container0' : 'button-container1'}>
                    {recievedData.map((item) => (
                        <button
                            key={item.id}
                            className={buttonFormat === 0 ? 'billButton0' : 'billButton1'}
                            onClick={() => this.onBillClick(item)}
                        >
                            <span className={buttonFormat === 0 ? 'seller-name0' : 'seller-name1'}>
                                {item.sellerNameERP} / {item.sellerName}
                            </span>
                            <span className={buttonFormat === 0 ? 'total-price0' : 'total-price1'}>
                                {this.props.getEURString(item.priceTotalERP, 2)} / {this.props.getEURString(item.priceTotal, 2)}
                            </span>
                            <span className={buttonFormat === 0 ? 'date0' : 'date1'}>
                                Bill ID: {item.billID}
                            </span>
                            <span className={buttonFormat === 0 ? 'date0' : 'date1'}>
                                {item.issueDateYear} {item.issueDateMonth} {item.issueDateDay}
                            </span>
                        </button>
                    ))} 
                </div>
            </div>
        );
    }
}

export default function BillListFunction(props) {
    const navigate = useNavigate();
    return <BillList {...props} navigate={navigate} />;
}