import React from 'react';
import './App.css';
import './BillView.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';

import { useNavigate } from "react-router-dom";

class BillView extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      billID: 0,
      sellerName: '',
      priceTotal: 0,
      vatTotal: 0,
      discount: 0,
      sellerNameERP: '',
      priceTotalERP: 0,
	  konto: 0,
	  kontoTitle: '',
	  businessUnit: 0,
	  businessUnitText: "",
	  placeOfExpenditure: 0,
	  placeOfExpenditureText: "",
	  paymentReferenceNumber: '',
      googleStorageID: '',
      userFullName: '',
      companyID: 0,
      year: 0,
	  pdfYN: 0,
      itemArray: null,
    };

	this.serverRemovePDF = this.serverRemovePDF.bind(this);
	this.onClickRemovePDF = this.onClickRemovePDF.bind(this);
	this.onClickViewJson = this.onClickViewJson.bind(this);
  }

  componentDidMount() {
    var selectedBillAutoID = this.props.selectedBillAutoID;
    this.serverGetBillData(selectedBillAutoID);
    this.serverGetBillItemList(selectedBillAutoID);
  }
  
  async getBusinessUnitText(companyID, businessUnit1) {
	  
	businessUnit1 = parseInt(businessUnit1);

	var baseURLAPI = this.props.baseURLAPI;
    var url = baseURLAPI + "/company/" + companyID + "/businessUnit.json";
	
	var jsonObj = null;

    try {
      var res = await fetch(url);
      jsonObj = await res.json();
	} catch (err) {
	}
	
	if (jsonObj === null) {
		return null;
	}
	  
	var i;
	var itemObj;
	var businessUnit2;

	for(i=0; i<jsonObj.length; i++) {
		itemObj = jsonObj[i];
		businessUnit2 = itemObj.id;
		if (businessUnit1 === businessUnit2) {
			return itemObj.text;
		}
	}

	return "";
  }

  async getPlaceOfExpenditureText(companyID, placeOfExpenditure1) {
	  
	var baseURLAPI = this.props.baseURLAPI;
    var url = baseURLAPI + "/company/" + companyID + "/placeOfExpenditure.json";
	
	var jsonObj = null;

    try {
      var res = await fetch(url);
      jsonObj = await res.json();
	} catch (err) {
	}
	
	if (jsonObj === null) {
		return null;
	}
	  
	var i;
	var itemObj;
	var placeOfExpenditure2;

	for(i=0; i<jsonObj.length; i++) {
		itemObj = jsonObj[i];
		placeOfExpenditure2 = itemObj.id;
		if (placeOfExpenditure1 === placeOfExpenditure2) {
			return itemObj.text;
		}
	}

	return "";
  }

  async serverGetBillData(billAutoID) {

    var baseURLAPI = this.props.baseURLAPI;
    var url = baseURLAPI + '/getbilldata?billautoid=' + billAutoID;

    try {
      var res = await fetch(url);
      var jsonObj = await res.json();
	  
      var billID = jsonObj.billID;
      var sellerName = jsonObj.sellerName;
      var priceTotal = jsonObj.priceTotal;
      var vatTotal = jsonObj.vatTotal;
      var discount = jsonObj.discount;
      var sellerNameERP = jsonObj.sellerNameERP;
      var priceTotalERP = jsonObj.priceTotalERP;
      var googleStorageID = jsonObj.googleStorageID;
      var userFullName = jsonObj.userFullName;
      var companyID = jsonObj.companyID;
      var year = jsonObj.year;
	  var konto = jsonObj.konto;
	  var kontoTitle = jsonObj.kontoTitle;
	  var businessUnit = jsonObj.businessUnit;
	  var placeOfExpenditure = jsonObj.placeOfExpenditure;
	  var paymentReferenceNumber = jsonObj.paymentReferenceNumber;
	  var pdfYN = jsonObj.pdfYN;

	  var businessUnitText = await this.getBusinessUnitText(companyID, businessUnit);
	  var placeOfExpenditureText = await this.getPlaceOfExpenditureText(companyID, placeOfExpenditure);

      this.setState({
        billID: billID,
        sellerName: sellerName,
        priceTotal: priceTotal,
		vatTotal: vatTotal,
		discount: discount,
		sellerNameERP: sellerNameERP,
		priceTotalERP: priceTotalERP,
		konto: konto,
		kontoTitle: kontoTitle,
		businessUnit: businessUnit,
		businessUnitText: businessUnitText,
		placeOfExpenditure: placeOfExpenditure,
		placeOfExpenditureText: placeOfExpenditureText,
		paymentReferenceNumber: paymentReferenceNumber,
        googleStorageID: googleStorageID,
        userFullName: userFullName,
        companyID: companyID,
        year: year,
		pdfYN: pdfYN,
      });
    } catch (err) {}
  }

  async serverGetBillItemList(billAutoID) {
    var baseURLAPI = this.props.baseURLAPI;
    var url = baseURLAPI + '/getbillitemlist?billautoid=' + billAutoID;

    try {
      var res = await fetch(url);
      var jsonObj = await res.json();

      this.setState({
        itemArray: jsonObj,
      });
    } catch (err) {}
  }

  renderProducts() {
    var itemArray = this.state.itemArray;

    if (itemArray === null) {
      return null;
    }

    return (
      <div className="bill-view">
        <div className="products-section">
          <table className="product-table">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Price per Unit</th>
                <th>VAT</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {itemArray.map((itemObj) => (
                <tr key={itemObj.billItemID}>
                  <td>{itemObj.productName}</td>
                  <td>{itemObj.quantity}</td>
                  <td>{this.props.getEURString(itemObj.priceUnit, 2)}</td>
                  <td>{itemObj.vatTotal}%</td>
                  <td>{this.props.getEURString(itemObj.priceTotal, 2)}</td>
                </tr>
              ))}
            </tbody>
            {/* Footer row for displaying the total price */}
            <tfoot>
              <tr>
                <td>
                  <strong>Total Bill Price:</strong>
                </td>
				<td></td>
				<td></td>
				<td></td>
                <td>
					<strong>{this.props.getEURString(this.state.priceTotal, 2)}</strong>
				</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  }
  
  async serverRemovePDF(billAutoID) {
	  
	var baseURLAPI = this.props.baseURLAPI;
    var url = baseURLAPI + "/removebillpdf?billautoid=" + billAutoID;
	
    var res = await fetch(url);
    var responseText = await res.text();	
	
	this.props.showToast("PDF removed");

    var selectedBillAutoID = this.props.selectedBillAutoID;
    this.serverGetBillData(selectedBillAutoID);
    this.serverGetBillItemList(selectedBillAutoID);
  }

  onClickRemovePDF() {

      var selectedBillAutoID = this.props.selectedBillAutoID;

	  confirmAlert({
		  title: 'Remove PDF',
		  message: 'Are you sure?',
		  buttons: [
			{
			  label: 'Yes',
			  onClick: () => {
				  this.serverRemovePDF(selectedBillAutoID);
			  }
			},
			{
			  label: 'No',
			  onClick: () => {
			  }
			}
		  ]
		});  
  }
  
  onClickViewJson() {
    const navigate = this.props.navigate;
    navigate("/bills/viewjson");
  }

  renderRemovePDFButton() {
	  
	  var pdfYN = this.state.pdfYN;
	  
	  if (pdfYN !== 1) {
		  return null;
	  }
	  
	  return (
		<button className="changeFormat" onClick={this.onClickRemovePDF}>Remove PDF</button>
	  );
  }

  renderDownloadPDF() {

    var googleStorageID = this.state.googleStorageID;
	if (googleStorageID === "") {
		return null;
	}

    var companyID = this.state.companyID;
    var year = this.state.year;

    var bucketName = 'bill-' + companyID + '-' + year;

    var pdfURL =
      'https://storage.googleapis.com/' +
      bucketName +
      '/' +
      googleStorageID +
      '.pdf';

    var jsonURL =
      'https://storage.googleapis.com/' +
      bucketName +
      '/' +
      googleStorageID +
      '.json';

    var viewJsonURL = "https://dashboardapi.plurato.com/viewbilljson?url=" + encodeURIComponent(jsonURL);

	return (
		<div>
			<a href={pdfURL} target="_blank" rel="noopener noreferrer">
				Download PDF
			</a> 
			&nbsp; - &nbsp;
			<a href={jsonURL} target="_blank" rel="noopener noreferrer">
				Download JSON
			</a>
			&nbsp; - &nbsp;
			<a href={viewJsonURL} target="_blank" rel="noopener noreferrer">
				View JSON
			</a>
		</div>
	);
  }		

  render() {
    var selectedBillAutoID = this.props.selectedBillAutoID;
    var billID = this.state.billID;
    var sellerName = this.state.sellerName;
    var priceTotal = this.state.priceTotal;
    var sellerNameERP = this.state.sellerNameERP;
    var priceTotalERP = this.state.priceTotalERP;
	var konto = this.state.konto;
	var kontoTitle = this.state.kontoTitle;
	var businessUnit = this.state.businessUnit;
	var businessUnitText = this.state.businessUnitText;
	var placeOfExpenditure = this.state.placeOfExpenditure;
	var placeOfExpenditureText = this.state.placeOfExpenditureText;
	var paymentReferenceNumber = this.state.paymentReferenceNumber;
    var vatTotal = this.state.vatTotal;
    var discount = this.state.discount;
    var googleStorageID = this.state.googleStorageID;
    var userFullName = this.state.userFullName;
    var companyID = this.state.companyID;
    var year = this.state.year;
    var pdfYN = this.state.pdfYN;

    return (
      <div>
        <div className="bill-header">
          <h1>Bill View</h1>
          <p>
            <strong>Bill Auto ID:</strong> {selectedBillAutoID}
          </p>
          <p>
            <strong>Bill ID:</strong> {billID}
          </p>
          <p>
            <strong>Seller Name:</strong> {sellerNameERP} / {sellerName}
          </p>
          <p>
            <strong>Discount:</strong>{' '}
            <span className="discount">{this.props.getEURString(discount, 2)}</span>
          </p>
          <p>
            <strong>VAT Total:</strong>{' '}
            <span className="vat">{this.props.getEURString(vatTotal, 2)}</span>
          </p>
          <p>
            <strong>Total Price:</strong>{' '}
            <span className="price">{this.props.getEURString(priceTotalERP, 2)} / {this.props.getEURString(priceTotal, 2)}</span>
          </p>
          <p>
            <strong>Konto:</strong>{' '}
            <span className="price">{konto} - {kontoTitle}</span>
          </p>
          <p>
            <strong>Business Unit:</strong>{' '}
            <span className="price">{businessUnit} {businessUnitText}</span>
          </p>
          <p>
            <strong>Place of Expenditure:</strong>{' '}
            <span className="price">{placeOfExpenditure} {placeOfExpenditureText}</span>
          </p>
          <p>
            <strong>Payment Reference Number:</strong>{' '}
            <span className="price">{paymentReferenceNumber}</span>
          </p>	  
          <p>
            <strong>User:</strong> {userFullName}
          </p>
		  {this.renderDownloadPDF()}
        </div>
        <div>{this.renderProducts()}</div>
        <div>{this.renderRemovePDFButton()}</div>
		<div style={{height: "20px"}}></div>
      </div>
    );
  }
}

export default function BillViewFunction(props) {
	const navigate = useNavigate();
	return <BillView {...props} navigate={navigate} />;
}

