import React  from 'react';
import './App.css';
import { AiOutlineDownload, AiOutlineDelete } from "react-icons/ai"
import { CgRename } from "react-icons/cg"
import { BiEdit } from "react-icons/bi"
//import { saveAs } from "file-saver";
//import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Dropzone from 'react-dropzone';
import './BillNew2.css';

import { useNavigate } from "react-router-dom";

class BillNew2 extends React.Component {

	constructor() {

		super();

		this.state = {
			uploadInProgressYN: false,
		};

		this.onDrop = (files) => {

			var len = files.length;

			if (len !== 1) {
				this.props.showToast("ERROR - You must select only one file.");
				return;
			}
			
			var filenameExt = files[0].name;			
			var results = this.getFilenamePartsFromFilenameExt(filenameExt);
			var filename = results.filename;
			var fileExt = results.fileExt;

			if (fileExt !== "pdf") {
				this.props.showToast("ERROR - You must select only PDF file.");
				return;
			}
			
			var billID = parseInt(filename);			
			var nanYN = isNaN(billID);
			
			if (nanYN) {
				this.props.showToast("ERROR - You must select file with this format: billNumber.pdf");
				return;
			}
			
			var file = files[0];
			this.uploadOneBill(file, billID);
		};

		this.getFilenamePartsFromFilenameExt = this.getFilenamePartsFromFilenameExt.bind(this);
		this.uploadOneBill = this.uploadOneBill.bind(this);
		this.getExtFromFilename = this.getExtFromFilename.bind(this);
	}
	
	componentDidMount() {
	}
	
	getFilenamePartsFromFilenameExt(filenameExt) {

		var i;
		var ch;
		var filename = "";
		var fileExt = "";
		var length = filenameExt.length;
		var result = {};

		for(i=length-1; i>=0; i--) {
			ch = filenameExt.charAt(i);
			if (ch === ".") {
				filename = filenameExt.substring(0, i);
				fileExt = filenameExt.substring(i+1, length);
				result.filename = filename;
				result.fileExt = fileExt.toLowerCase();
				return result;
			}
		}
		
		filename = filenameExt;
		fileExt = "";
		result.filename = filename;
		result.fileExt = fileExt;
		return result;
	}

	getExtFromFilename(filename) {
		var ext = filename.substr(filename.lastIndexOf('.') + 1);
		return ext.toLowerCase();
	}

	async uploadOneBill(file, billID) {
		
		console.log("billID = " + billID);

		this.setState({
			uploadInProgressYN: true,
		});

		var baseURLAPI = this.props.baseURLAPI;
		var userID = this.props.userID;
		var fullName = this.props.fullName;
		var companyID = this.props.companyID;
		var sizeBytes = file.size;
		var year = 2024;
		
		var url = baseURLAPI + "/uploadbill?year=" + year;
		url = url + "&billid=" + billID;
		url = url + "&sizebytes=" + sizeBytes;
		url = url + "&userid=" + userID;
		url = url + "&fullname=" + encodeURIComponent(fullName);
		url = url + "&companyid=" + companyID;
				
		var formData = new FormData()
		formData.append('file', file)

		var jsonObj = null;

		try {
			var res = await fetch(url, {
				method: 'POST',
				body: formData
			});

			jsonObj = await res.json();
		} catch (err) {
		}
		
		this.setState({
			uploadInProgressYN: false,
		});

		console.log(jsonObj);

		if (jsonObj === null) {
			this.props.showToast("ERROR - Unknown error occured");
			return;
		}

		var rt = jsonObj.rt;

		if (rt === 1) {
			this.props.showToast("ERROR - Bill with same number already exists");
			return;
		}

	}
	
	renderUploadInProgress() {

		var uploadInProgressYN = this.state.uploadInProgressYN;
		if (!uploadInProgressYN) {
			return null;
		}
		
		return (
			<div>
				<div>Bill upload in progress...</div>
				<div style={{height: "10px"}}></div>
			</div>
		);
	}
	
	render() {

		return (
			<div>
				<div style={{height: "10px"}}></div>
				{this.renderUploadInProgress()}
				<Dropzone onDrop={this.onDrop}>
				{({getRootProps, getInputProps}) => (
				  <section>
					<div {...getRootProps({className: 'dropzone'})}>
					  <input {...getInputProps()} />
					  <div className="container">Drag 'n' drop one PDF file here, or click to select one PDF file</div>
					</div>
					<div style={{height: "15px"}}></div>
				  </section>
				)}
				</Dropzone>
			</div>
		);
	}
}

export default function BillNew2Function(props) {
	const navigate = useNavigate();
	return <BillNew2 {...props} navigate={navigate} />;
}

