import React  from 'react';
import './TopbarPopupMenu.css';
import { Tooltip } from 'react-tooltip';
import { useNavigate } from "react-router-dom";
import TopbarLang from './TopbarLang.json';
import Flag from 'react-world-flags'

class TopbarPopupMenu extends React.Component {

	constructor(props) {

		super(props);
		this.ref = React.createRef();

		this.serverUpdateUserLanguage = this.serverUpdateUserLanguage.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.onAccountButtonClicked = this.onAccountButtonClicked.bind(this);
		this.onLogoutButtonClicked = this.onLogoutButtonClicked.bind(this);
		this.onFlagClicked = this.onFlagClicked.bind(this);		
		this.renderFlags = this.renderFlags.bind(this);		
	}

	async serverUpdateUserLanguage(userID, language) {

		var managementBaseURLAPI = this.props.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/updateuserlanguage?userid=" + userID + "&language=" + language;
		
		const res = await fetch(url);
		await res.text();
	}

	handleClickOutside(event) {
		if (this.ref.current && !this.ref.current.contains(event.target)) {
			this.props.closePopupMenu();
		}
	}

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside.bind(this), true);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside.bind(this), true);
	};

	onAccountButtonClicked(page) {

		var dbtouch = this.props.dbtouch;

		var sessionID = this.props.sessionID;
		var accountURL;
		
		if (dbtouch === 0) {
			accountURL = "https://apps.plurato.com/myaccount?sid=" + encodeURI(sessionID);
		} else {
			accountURL = "https://management.dbtouch.com";
		}

		const newWindow = window.open(accountURL, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
		this.props.closePopupMenu();
	}
	
	onLogoutButtonClicked(page) {
		this.props.logoutClearVariables();
		const navigate = this.props.navigate;
  		navigate("/login");
		this.props.closePopupMenu();
	}
	
	onFlagClicked(language) {
		var userID = this.props.userID;
		this.serverUpdateUserLanguage(userID, language);
		this.props.setLanguage(language);
	};

	renderFlags() {
		
		return (
			<div className="TopbarPopupMenuFlagContainter">
				<div className="TopbarPopupMenuFlagItem" data-tip="English" onClick={() => {this.onFlagClicked("en")}}>
					<Flag code="gbr" height="16" />
				</div>
				<div className="TopbarPopupMenuFlagItem" data-tip="Hrvatski" onClick={() => {this.onFlagClicked("hr")}}>
					<Flag code="hrv" height="16" />
				</div>
			</div>
		);
	}
	
	render() {
		
		var email = this.props.email;
		
		return (
			<div ref={this.ref} className="TopbarPopupMenuContainer1">
				<Tooltip
					type="info"
					delayShow={500}
				/>
				<div className="TopbarPopupMenuArrow"></div>
				<div className="TopbarPopupMenuEmailText">{email}</div>
				<div style={{height: "15px"}}></div>
				{this.renderFlags()}
				<div style={{height: "15px"}}></div>
				<button className="TopbarPopupMenuAccountButton" onClick={this.onAccountButtonClicked}>{this.props.getLang(TopbarLang, "myAccount")}</button>
				<div style={{height: "15px"}}></div>
				<button className="TopbarPopupMenuLogoutButton" onClick={this.onLogoutButtonClicked}>Logout</button>
			</div>
		);
	}
}

export default function TopbarPopupMenuFunction(props) {
	const navigate = useNavigate();
	return <TopbarPopupMenu {...props} navigate={navigate} />;
}

