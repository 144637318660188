import React from 'react';
import './App.css';
import './Navbar.css';
import { AiOutlineEuroCircle, AiOutlineLogout } from "react-icons/ai"
import { BsCardImage, BsCardList, BsDatabaseCheck } from "react-icons/bs"
import { FaInfo, FaChartLine, FaRegChartBar } from "react-icons/fa"

import AppLang from './AppLang.json';

import { useNavigate } from "react-router-dom";

class Navbar extends React.Component {

	constructor(props) {

		super(props);

		this.handleClick = this.handleClick.bind(this);		
	}
	
	componentDidMount() {
	}

	componentWillUnmount() {
	};
	
	handleClick(page) {

		const navigate = this.props.navigate;

		if (page === "/logout") {
			this.props.logoutClearVariables();
			navigate("/login");
		} else {
			navigate(page);
		}

		this.props.closeNavbar();
	}
	
	render() {
		
		var appUserType = this.props.appUserType;

		/*var showEditDashboardYN = false;
		if (appUserType >= 100) {
			showEditDashboardYN = true;
		}*/

		var navbarOpenYN = this.props.navbarOpenYN;
		if (!navbarOpenYN) {
			return (<div></div>);
		}
		
		return (
			<div id="navbar" className="Navbar">

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/bills/list")}
				>
					<div className="NavbarLinkColumn1">
						<BsDatabaseCheck style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "billList")}</div>
				</div>

				{/*<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/bills/new")}
				>
					<div className="NavbarLinkColumn1">
						<AiOutlineEuroCircle style={{ color: "white", width: "28px", height: "28px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "newBill")}</div>				  					  
				</div>*/}

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/bills/new2")}
				>
					<div className="NavbarLinkColumn1">
						<BsCardImage style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "newBill")}</div>
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/bills/newmulti")}
				>
					<div className="NavbarLinkColumn1">
						<BsCardList style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "newBillMulti")}</div>
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/about")}
				>
					<div className="NavbarLinkColumn1">
						<FaInfo style={{ color: "white", width: "25px", height: "20px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "about")}</div>				  					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/logout")}
				>
					<div className="NavbarLinkColumn1">
						<AiOutlineLogout style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">Logout</div>
				</div>

			</div>
		);
	}
}

export default function NavbarFunction(props) {
	const navigate = useNavigate();
	return <Navbar {...props} navigate={navigate} />;
}

