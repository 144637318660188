import React  from 'react';
import './App.css';

class BillViewJson extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			googleStorageID: "",
		};
	}

	componentDidMount() {
		var selectedBillAutoID = this.props.selectedBillAutoID;
		this.serverGetBillData(selectedBillAutoID);
	}

	async serverGetBillData(billAutoID) {

	var baseURLAPI = this.props.baseURLAPI;
	var url = baseURLAPI + '/getbilldata?billautoid=' + billAutoID;

	try {
		var res = await fetch(url);
		var jsonObj = await res.json();

		var googleStorageID = jsonObj.googleStorageID;

		this.setState({
			googleStorageID: googleStorageID,
		});
	} catch (err) {}
  }

	render() {
		
		var googleStorageID = this.state.googleStorageID;
		
		return (
			<div>
				<p>BillViewJson - {googleStorageID}</p>
			</div>
		);
	}
}

export default BillViewJson;

